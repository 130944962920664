<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.nomenclador')} / ${$t('menu.cars')} / ${$t('menu.rentadora')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'rentadoras-cars-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="9"
              >
                <v-row class="pb-5">
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="model.name"
                      :label="$t('lbl.name')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-file-input
                      v-model="avatar"
                      accept="image/*"
                      show-size
                      outlined
                      dense
                      label="Logo"
                      hide-details
                      @change="setAvatar"
                    ></v-file-input>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-switch
                      v-model="model.is_company"
                      :label="$t('lbl.isCompany')"
                      hide-details
                      class="mt-2"
                    ></v-switch>
                  </v-col>
                  <!--<v-col
                    cols="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="model.nom_oficina_renta_cars_id"
                      class="pt-2"
                      :items="oficinasFiltradas"
                      :label="$t('menu.oficinaRenta')"
                      outlined
                      hide-details
                      dense
                      chips
                      small-chips
                      multiple
                      item-text="name"
                      item-value="id"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>-->
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-img
                  contain
                  class="justify-center pb-5"
                  :src="previewImage"
                  width="100%"
                  max-height="100px"
                ></v-img>
              </v-col>

              <v-col
                v-if="model.is_company"
                cols="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <strong>{{ $t('menu.products') }}</strong>
                    <v-row>
                      <v-col
                        v-for="(product, i) in products"
                        :key="i"
                        cols="12"
                        md="3"
                      >
                        <v-checkbox
                          v-model="nom_tipo_products_id"
                          :label="product.name"
                          :value="product.id"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-row class="mt-4">
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-row>
                          <v-col
                            cols="12"
                            md="2"
                            class="mt-2"
                          >
                            <strong>{{ $t('lbl.contacts') }}</strong>
                          </v-col>
                          <v-col
                            cols="12"
                            md="1"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="mt-1 mx-2"
                                  fab
                                  dark
                                  x-small
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="addContactsAfiliados"
                                >
                                  <v-icon small>
                                    {{ icons.mdiPlus }}
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('btn.insert') }}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>

                        <v-divider class="mb-2 mt-2"></v-divider>
                        <v-row
                          v-for="(contact, indC) in contactsAfiliados"
                          :key="indC"
                        >
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <Contact
                              :pos="indC"
                              :contact="contact"
                              :product-item="nom_tipo_products_id"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-row
                              v-for="(email, indE) in contact.emails"
                              :key="indE"
                            >
                              <Email
                                :pos-contact="indC"
                                :pos="indE"
                                :email="email"
                              />
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-row
                              v-for="(phone, indT) in contact.telefonos"
                              :key="indT"
                            >
                              <Telefono
                                :pos-contact="indC"
                                :pos="indT"
                                :telefono="phone"
                              />
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <!--<v-col
                cols="12"
                md="12"
              >
                <v-divider></v-divider>
              </v-col>-->
              <v-col
                cols="12"
                md="12"
              >
                <strong class="mt-5">{{ $t('lbl.gestionarOficinas') }}</strong>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="search.country"
                  :items="countries"
                  :label="$t('lbl.country')"
                  outlined
                  dense
                  hide-details
                  @change="changeCountry"
                ></v-select>
              </v-col>
              <v-col
                v-if="search.country"
                cols="12"
                md="3"
              >
                <v-select
                  v-model="search.state"
                  :items="states"
                  :label="$t('lbl.provinceEstado')"
                  outlined
                  dense
                  hide-details
                  clearable
                  @change="changeState"
                ></v-select>
              </v-col>
              <v-col
                v-if="search.state"
                cols="12"
                md="3"
              >
                <v-select
                  v-model="search.city"
                  :items="cities"
                  :label="$t('lbl.locality')"
                  outlined
                  dense
                  hide-details
                  clearable
                  @change="changeCity"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-divider></v-divider>
              </v-col>

              <v-col
                cols="12"
                md="1"
              >
                <v-btn
                  v-if="search.country"
                  class="mx-2"
                  fab
                  outlined
                  small
                  color="error"
                  @click="clearSearch"
                >
                  <v-icon small>
                    {{ icons.mdiEraserVariant }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="search.country && modelOficinas.length === 0"
                cols="12"
                md="9"
                class="text-center"
              >
                <!--:loading="loadingOficinas"-->
                <v-btn
                  color="primary"
                  class="mb-4 me-3"
                  @click="filterOficinas"
                >
                  <v-icon right>
                    {{ icons.mdiMagnify }}
                  </v-icon>
                  <span class="pl-5">{{ $t('btn.search') }}</span>
                </v-btn>
              </v-col>
              <v-col
                v-if="totalItems > 0"
                cols="12"
                md="2"
              >
                {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.oficinas') | lowercase }}
              </v-col>
            </v-row>
            <v-row
              v-if="modelOficinas.length > 0"
              class="mb-5"
            >
              <v-spacer></v-spacer>

              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="itemsPerPage"
                  :label="$t('lbl.itemsPerPage')"
                  type="number"
                  min="5"
                  hide-details
                  dense
                  outlined
                  @input="onPageChange()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-simple-table
              v-if="modelOficinas.length > 0"
              dense
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th width="0.1%">
                      <!--<v-switch
                        v-model="modelOficinasAll"
                        :label="$t('lbl.checkAll')"
                        @change="changeAll"
                      ></v-switch>-->
                      <v-checkbox
                        v-model="modelOficinasAll"
                        hide-details
                        class="my-0 mx-0"
                        @change="changeAll"
                      ></v-checkbox>
                    </th>
                    <th
                      width="15%"
                      class="text-uppercase"
                    >
                      {{ $t('lbl.oficina') }}
                    </th>
                    <th
                      width="5%"
                      class="text-center"
                    >
                      <v-icon>
                        {{ icons.mdiInformation }}
                      </v-icon>
                    </th>
                    <th
                      width="20%"
                      class="text-uppercase"
                    >
                      {{ $t('lbl.telefonos') }}
                    </th>
                    <th
                      width="30%"
                      class="text-uppercase"
                    >
                      {{ $t('lbl.horarios') }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!paginando">
                  <fragment
                    v-for="(iten, index) in modelOficinasPaginate"
                    :key="index"
                  >
                    <FilterOficina
                      :oficina="iten"
                      :index="index"
                    />
                  </fragment>
                </tbody>
              </template>
            </v-simple-table>
            <!--PAGINADO-->
            <v-row v-if="modelOficinas.length > 0">
              <v-col
                lg="12"
                cols="12"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="pagination.current"
                  :length="pagination.total"
                  total-visible="5"
                  @input="onPageChange"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiCheck,
  mdiEraserVariant,
  mdiInformation,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import Contact from '@/views/user_register/utils/Contact.vue'
// eslint-disable-next-line import/no-unresolved
import Email from '@/views/user_register/utils/Email.vue'
// eslint-disable-next-line import/no-unresolved
import Telefono from '@/views/user_register/utils/Telefono.vue'

import FilterOficina from '../utils/FilterOficina.vue'

export default {
  components: {
    AppCardCode,
    FilterOficina,
    Contact,
    Email,
    Telefono,
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiCheck,
        mdiEraserVariant,
        mdiInformation,
      },
      search: {
        country: null,
        state: null,
        city: null,
      },
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      model: {},
      previewImage: null,
      avatar: null,
      loading: false,
      loadingOficinas: false,

      oficinas: [],
      oficinasFiltradas: [],
      modelOficinas: [],
      modelOficinasPaginate: [],
      modelOficinasAll: false,
      paginando: false,
      countries: [],
      states: [],
      cities: [],
      totalF: 0,
      totalItems: 0,
      products: [],
      nom_tipo_products_id: [],
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('rentadora-car-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      contactsAfiliados: state => state.app.contactsAfiliados,
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
      oficceRent: state => state.app.oficceRent,
      paiseCode: state => state.app.paiseCode,
    }),
  },
  created() {
    this.getProducts()
    this.getOficinas()

    // this.getItem()
  },
  methods: {
    ...mapMutations([
      'adicionarTelefono',
      'updateTelefonos',
      'editOficinaRenta',
      'updateGeoTag',
      'adicionarHorario',
      'updateHorarios',
      'setOnlyShow',
      'updateOficceRent',
      'deleteOficceRent',
      'adicionarOficceRent',
      'setPaisCode',
      'addContactsAfiliados',
      'deleteContactsAfiliados',
      'updateContactsAfiliados',
    ]),
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.products = res.data.data
          this.products.forEach(element => {
            if (element.slug === 'cars') {
              this.nom_tipo_products_id.push(element.id)
            }
          })
        })
    },

    getItem() {
      if (localStorage.getItem('rentadora-car-id') !== null) {
        const id = localStorage.getItem('rentadora-car-id')
        this.axios
          .get(`nom_rentadora_cars/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            if (this.model.nom_tipo_products_id) {
              this.nom_tipo_products_id = this.model.nom_tipo_products_id
            } else {
              this.nom_tipo_products_id = []
            }

            if (this.model.contacts !== null && this.model.contacts !== 'null') {
              this.updateContactsAfiliados(this.model.contacts)
            } else {
              this.updateContactsAfiliados([])
            }

            // PARA CARGAR EL SEARCH COMO ESTABA ANTERIOR
            if (this.model.last_search) {
              this.search = this.model.last_search
              const states = this.oficinas.filter(e => e.country === this.search.country)
              states.forEach(element => {
                if (!this.states.includes(element.state) && element.state) {
                  this.states.push(element.state)
                }
              })
              if (this.search.state) {
                const stado = this.oficinas.filter(e => e.state === this.search.state)
                stado.forEach(element => {
                  if (!this.cities.includes(element.city)) {
                    this.cities.push(element.city)
                  }
                })
              }

              this.filterOficinas()
            }
            if (this.model.oficinas) {
              this.updateOficceRent(this.model.oficinas)
            } else {
              this.updateOficceRent([])
            }

            /* this.oficinas.forEach(element => {
              if (this.model.nom_oficina_renta_cars_id) {
                if (this.model.nom_oficina_renta_cars_id.includes(element.id)) {
                  this.oficinasFiltradas.push({
                    check: true,
                    id: element.id,
                    name: element.name,
                    direccion: element.direccion,
                  })
                }
              }
            }) */

            if (this.model.icon) {
              this.previewImage = process.env.VUE_APP_API_URL_PLUBLIC + this.model.icon
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.isLoading = false
      }
    },
    setAvatar() {
      this.previewImage = URL.createObjectURL(this.avatar)
    },
    getOficinas() {
      this.axios
        .get('nom_oficina_renta_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.oficinas = res.data.data
          }
        })
        .finally(() => {
          this.getCountries()
          this.getItem()
        })
    },

    getCountries() {
      this.oficinas.forEach(oficce => {
        if (!this.countries.includes(oficce.country)) {
          if (oficce.country) {
            this.countries.push(oficce.country)
          }
        }
      })
    },
    changeCountry() {
      this.modelOficinas = []
      this.states = []
      this.search.state = null
      this.search.city = null
      if (this.search.country) {
        const states = this.oficinas.filter(e => e.country === this.search.country)
        states.forEach(element => {
          if (!this.states.includes(element.state) && element.state) {
            this.states.push(element.state)
          }
        })
      }
    },
    changeState() {
      this.modelOficinas = []
      this.cities = []
      this.search.city = null
      if (this.search.state) {
        const states = this.oficinas.filter(e => e.state === this.search.state)
        states.forEach(element => {
          if (!this.cities.includes(element.city)) {
            this.cities.push(element.city)
          }
        })
      }
    },
    changeCity() {
      this.modelOficinas = []
    },
    filterOficinas() {
      this.loadingOficinas = true
      if (this.search.country) {
        if (this.search.state) {
          if (this.search.city) {
            const result = this.oficinas.filter(
              e => e.country === this.search.country && e.state === this.search.state && e.city === this.search.city,
            )
            result.forEach(element => {
              this.modelOficinas.push({
                check: false,
                id: element.id,
                name: element.name,
                direccion: element.direccion,
                country: element.country,
                state: element.state,
                city: element.city,
                latitud: element.latitud,
                longitud: element.longitud,
              })
            })
            this.pagination.total = Math.ceil(result.length / this.itemsPerPage)
            this.modelOficinasPaginate = this.modelOficinas.slice(0, this.itemsPerPage)
          } else {
            const result = this.oficinas.filter(e => e.country === this.search.country && e.state === this.search.state)
            result.forEach(element => {
              this.modelOficinas.push({
                check: false,
                id: element.id,
                name: element.name,
                direccion: element.direccion,
                country: element.country,
                state: element.state,
                city: element.city,
                latitud: element.latitud,
                longitud: element.longitud,
              })
            })
            this.pagination.total = Math.ceil(result.length / this.itemsPerPage)
            this.modelOficinasPaginate = this.modelOficinas.slice(0, this.itemsPerPage)
          }
        } else {
          const result = this.oficinas.filter(e => e.country === this.search.country)
          result.forEach(element => {
            this.modelOficinas.push({
              check: false,
              id: element.id,
              name: element.name,
              direccion: element.direccion,
              country: element.country,
              state: element.state,
              city: element.city,
              latitud: element.latitud,
              longitud: element.longitud,
            })
          })
          this.pagination.total = Math.ceil(result.length / this.itemsPerPage)
          this.modelOficinasPaginate = this.modelOficinas.slice(0, this.itemsPerPage)
        }

        setTimeout(() => {
          this.loadingOficinas = false
        }, 500)
      }
    },
    changeAll() {
      if (this.modelOficinasAll) {
        this.modelOficinas.forEach(element => {
          // eslint-disable-next-line no-undef
          let incluido = false
          this.oficceRent.forEach(ooo => {
            if (ooo.oficina_id === element.id) {
              incluido = true
            }
          })
          if (!incluido) {
            if (this.paiseCode.name !== element.country) {
              this.axios
                .post(
                  'get/code-phone',
                  { id: element.id, latitude: element.latitud, longitude: element.longitud },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                    },
                  },
                )
                .then(res => {
                  this.setPaisCode({
                    name: element.country,
                    countrySlug: res.data.country_code,
                    code: res.data.countryCode,
                  })
                })
                .finally(() => {
                  let code = 53
                  if (this.paiseCode.code !== null && this.paiseCode.code !== 'null') {
                    code = this.paiseCode.code
                  }
                  this.adicionarOficceRent({
                    oficina_id: element.id,
                    telefonos: [{ code, number: null, ext: null }],
                    horarios: [
                      {
                        modelSemana: [0, 1, 2, 3, 4, 5, 6],
                        is_time_completo: true,
                        horarios: [],
                      },
                    ],
                  })
                })
            }
          }
        })

        this.modelOficinas.forEach(element => {
          // eslint-disable-next-line no-param-reassign
          element.check = true
        })
      } else {
        this.modelOficinas.forEach(element => {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.oficceRent.length; index++) {
            const elementOfic = this.oficceRent[index]

            if (elementOfic.oficina_id === element.id) {
              this.deleteOficceRent(index)
              // eslint-disable-next-line no-param-reassign
              element.check = false
            }
          }
        })
      }
    },
    changeOne(check) {
      if (!check) {
        this.modelOficinasAll = false
      }
    },
    onPageChange() {
      this.paginando = true
      this.modelOficinasPaginate = this.modelOficinas.slice(
        (this.pagination.current - 1) * this.itemsPerPage,
        this.pagination.current * this.itemsPerPage,
      )
      this.pagination.total = Math.ceil(this.modelOficinas.length / this.itemsPerPage)
      setTimeout(() => {
        this.paginando = false
      }, 100)
    },

    addOficinas() {
      if (this.oficinasFiltradas.length === 0) {
        this.modelOficinas.forEach(element => {
          if (element.check) {
            this.oficinasFiltradas.push(element)
          }
        })
      } else {
        this.modelOficinas.forEach(element => {
          let existe = false
          this.oficinasFiltradas.forEach(oficce => {
            if (oficce.id === element.id) {
              existe = true
            }
          })
          if (!existe) {
            if (element.check) {
              this.oficinasFiltradas.push(element)
            }

            // this.model.nom_oficina_renta_cars_id.push(element.id)
          }
        })
      }
      setTimeout(() => {
        this.modelOficinas = []
        this.modelOficinasPaginate = []
        this.changeAll = false
        this.search = {
          country: null,
          state: null,
          city: null,
        }
        const arr = []
        this.oficinasFiltradas.forEach(element => {
          arr.push(element.id)
        })
        this.model.nom_oficina_renta_cars_id = arr
      }, 500)
    },

    save() {
      if (this.model.name /* && this.oficceRent.length > 0 */) {
        this.loading = true

        const arrIds = []
        this.oficceRent.forEach(element => {
          arrIds.push(element.oficina_id)
        })
        this.model.nom_oficina_renta_cars_id = arrIds
        this.model.oficinas = this.oficceRent
        this.model.last_search = this.search

        this.model.nom_tipo_products_id = this.nom_tipo_products_id
        this.model.contacts = this.contactsAfiliados

        const formData = new FormData()
        if (this.avatar) {
          formData.append('icon', this.avatar)
        }
        formData.append('data', JSON.stringify(this.model))

        // console.log(json)

        if (localStorage.getItem('rentadora-car-id') === null) {
          this.axios
            .post('nom_rentadora_cars', formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.rentadoraExist', { n: this.model.name }))
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
                localStorage.removeItem('rentadora-car-id')
                localStorage.removeItem('rentadora-car-show')
                this.$router.push({ name: 'rentadoras-cars-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .post(`nom_rentadora_cars/actualice/${this.model.id}`, formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                localStorage.removeItem('rentadora-car-id')
                localStorage.removeItem('rentadora-car-show')
                this.$router.push({ name: 'rentadoras-cars-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    clearSearch() {
      this.search = {
        country: null,
        state: null,
        city: null,
      }
    },
  },
}
</script>
